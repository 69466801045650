import _keyBy from 'lodash/keyBy';
import _mapValues from 'lodash/mapValues';
import _omitBy from 'lodash/omitBy';

import { API_RESOURCES } from './constants';

/**
 * Returns checklists list for prints with extra prop `print` = Print_Uri
 *
 * @param api `ky` extended object, which is used to make api calls. See /utils/api.js for details.
 *             TODO: It maybe makes sense to import it directly from utils
 * @param prints Array List of Print objects (/print/ resource)
 */

export const getChecklistsForPrintsByPrintUri = async (api, prints) => {
  if (!prints.length) {
    return {};
  }
  const workChecklistsFor = await api
    .get(`${API_RESOURCES.WORK_CHECKLIST_FOR_RESOURCE}/`, {
      searchParams: {
        'filter[related_uri]': prints.map(print => print.uri).join(','),
      },
    })
    .json();

  const workChecklistsForResourcesByPrintUri = _keyBy(workChecklistsFor.resources, 'related_uri');
  return _omitBy(
    _mapValues(
      workChecklistsForResourcesByPrintUri,
      resource =>
        resource.work_checklist_linkings.length &&
        resource.work_checklist_linkings[0].work_checklist
    ),
    item => !item
  );
};

/**
 * Adds document object as report (if instruction report is document-related report) and returns updated array.
 * Document response example (object, which is patched as "report" key) from /document/ service:
 *  {
 "content": null,
 "name": "document.png",
 "virus_scan": "unscanned",
 "related_table_name": "work_instruction_report",
 "related_uuid": "...",
 "upload_location": "https://...",
 "uri": "https://.../document/c7936f57-93b5-4563-8253-905d1e8c6381/"
 },
 *
 * @param workInstructionReports Array of work instruction reports to patch
 * @param documentByUUIDs Object of document records, uuid as key
 */
export const includeReportDocuments = (workInstructionReports, documentByUUIDs) => {
  if (!workInstructionReports.length) {
    return workInstructionReports;
  }

  return workInstructionReports.map(report => {
    const { report: value } = report;
    const document = documentByUUIDs[value];

    if (document) {
      return {
        ...report,
        report: document,
      };
    }

    // Parse any report value that is valid JSON.
    // For now, this is for the range type.
    try {
      report.report = JSON.parse(value);
    } catch (_) {
      // We can have invalid JSON for the `text` type, but in such cases, we must use it as it was provided.
    }

    return report;
  });
};
