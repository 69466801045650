import {
  faArrowsSpin,
  faArrowUpShortWide,
  faBoxArchive,
  faDownLeftAndUpRightToCenter,
  faLocationDot,
  faObjectGroup,
} from '@fortawesome/free-solid-svg-icons';
import { faBoxOpen, faCoins, faExternalLink, faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import BatchPowderQualityStatusIcon from 'src/components/BatchPowderQualityStatusIcon';
import Loader from 'src/components/loader';
import MaterialLotCardDetails from 'src/components/MaterialLotCardDetails';
import PermanentContainerCardDetails from 'src/components/PermanentContainerCardDetails';
import StatusColorCode from 'src/components/status-color-dot';
import TruncatedTitle from 'src/components/TruncatedTitle';
import {
  BATCH_CARD_TABS,
  LOCALSTORAGE_KEYS,
  MATERIAL_BATCH_STATUS_VERBOSE,
  STATUS_COLOR_CODE_TYPES,
} from 'src/utils/constants';
import routes from 'src/utils/routes';
import { getRouteURI, getShortUuid } from 'src/utils/url';
import useLocalStorage from 'src/utils/useLocalStorage';

export const BatchCardExpanded = ({
  batch,
  togglePreview,
  subLocation,
  cardState,
  allContainers,
  allLocationsByUri,
  allSubLocationsByUri,
  materialLots,
  shouldShowBatchLink,
  isMobileDevice,
}) => {
  const [debugMode] = useLocalStorage(LOCALSTORAGE_KEYS.DEBUG_MODE);
  const [activeTab, setActiveTab] = useState(BATCH_CARD_TABS.CONTAINERS);
  const [locationShown, setLocationShown] = useState(false);
  const activeIndex = 0;
  const slickRef = useRef(null);

  const lotsFetching = cardState[BATCH_CARD_TABS.LOTS].fetching;
  const containersFetching = cardState[BATCH_CARD_TABS.CONTAINERS].fetching;

  const handleSwitchTab = tab => setActiveTab(tab);
  const toggleLocation = () => setLocationShown(previous => !previous);

  const renderLotsTab = () => {
    if (lotsFetching && !materialLots.length) {
      return <Loader showText={false} />;
    }

    if (!materialLots.length) {
      return (
        <p className='no-card-resources'>
          <FontAwesomeIcon icon={faInfoCircle} className='d-block spacer-right batch-info-color' />
          Material Lots not found.
        </p>
      );
    }

    return materialLots.map(lot => (
      <MaterialLotCardDetails
        key={lot.uri}
        lot={lot}
        materials={batch.materials}
        debugMode={debugMode}
        location={allLocationsByUri[lot.location]}
        subLocation={allSubLocationsByUri[lot.sub_location]}
        state={cardState[BATCH_CARD_TABS.LOTS]}
        customMainStyle={{ margin: '20px 25px' }}
      />
    ));
  };

  const renderContainersTab = () => {
    if (containersFetching && !allContainers.length) {
      return <Loader showText={false} />;
    }

    if (!allContainers.length) {
      return (
        <p className='no-card-resources'>
          <FontAwesomeIcon icon={faInfoCircle} className='d-block spacer-right batch-info-color' />
          Batch Containers not found.
        </p>
      );
    }

    return allContainers.map(container => (
      <PermanentContainerCardDetails
        key={container.uri}
        swapRightIconPosition
        permanentContainer={container}
        isError={cardState[BATCH_CARD_TABS.CONTAINERS].error}
        location={allLocationsByUri[container.location]?.name}
        subLocation={allSubLocationsByUri[container.sub_location]?.name}
        customMainStyle={{ margin: '20px 25px', boxShadow: '0 0 4px 2px rgba(0, 0, 0, 0.08)' }}
      />
    ));
  };

  return (
    <div className='batch-card-expanded'>
      <div className='batch-card-expanded-header'>
        <div className='batch-card-expanded-header-main'>
          <div
            className='batch-card-expanded-header-main-details'
            style={isMobileDevice ? { marginBottom: '15px' } : {}}
          >
            <div className='text-left'>
              <p className='batch-card-expanded-header-main-batch-title'>
                <FontAwesomeIcon icon={faBoxArchive} className='spacer-right' />
                Batch
              </p>
              {shouldShowBatchLink ? (
                <Link
                  className='batch-card-main-details-main-link'
                  to={getRouteURI(routes.materialContainer, {}, { batch: batch.uuid })}
                >
                  <FontAwesomeIcon icon={faExternalLink} className='spacer-right' />
                  <p className='mb0'>{getShortUuid(batch.uuid)}</p>
                </Link>
              ) : (
                <p className='mb0 batch-card-main-details-main-link'>{getShortUuid(batch.uuid)}</p>
              )}
            </div>

            {!isMobileDevice && (
              <div className='batch-card-expanded-header-location'>
                <FontAwesomeIcon icon={faLocationDot} className='mr-1 text-white mt5' />
                <div className='batch-card-expanded-header-location-details'>
                  <TruncatedTitle
                    title={batch.location_name}
                    titleClassName='mb0 font-medium font-size-14'
                    maxTextLength={25}
                    maxTextLengthMobile={20}
                  />
                  <TruncatedTitle
                    title={subLocation}
                    titleClassName='mb0 font-light font-size-12'
                    maxTextLength={35}
                    maxTextLengthMobile={30}
                  />
                </div>
              </div>
            )}
          </div>
          <div>
            <div className='batch-card-expanded-header-status-details'>
              <div className='d-flex align-items-center'>
                <p className='mb0 d-flex align-items-center'>
                  <StatusColorCode
                    status={batch.status}
                    type={STATUS_COLOR_CODE_TYPES.BATCH_STATUS}
                    sizeClassName='small'
                  />
                </p>
                <p className='mb0 spacer-left text-white'>
                  {MATERIAL_BATCH_STATUS_VERBOSE[batch.status]}
                </p>
              </div>
              {batch.powder_quality && (
                <div className='spacer-left'>
                  <BatchPowderQualityStatusIcon qualityStatus={batch.powder_quality} />
                </div>
              )}
            </div>
            {isMobileDevice && (
              <div className='mt5 text-left'>
                <div className='cursor-pointer'>
                  <p
                    className='mb0 badge badge-secondary batch-card-expanded-location-mobile-btn'
                    onClick={toggleLocation}
                  >
                    {!locationShown ? 'Show' : 'Hide'} Location
                  </p>
                </div>
                {locationShown ? (
                  <div className='batch-card-expanded-location-mobile'>
                    <div>
                      <div className='d-flex align-items-center'>
                        <FontAwesomeIcon className='spacer-right' icon={faLocationDot} />
                        <TruncatedTitle
                          title={batch.location_name}
                          titleClassName='mb0 font-medium font-size-14'
                          maxTextLength={25}
                          maxTextLengthMobile={50}
                        />
                      </div>
                      <p className='badge batch-card-expanded-sub-location-mobile'>
                        <TruncatedTitle
                          title={subLocation}
                          titleClassName='mb0 font-light font-size-12'
                          maxTextLength={35}
                          maxTextLengthMobile={60}
                        />
                      </p>
                    </div>
                  </div>
                ) : null}
              </div>
            )}
          </div>
        </div>
        <div className='batch-card-expanded-header-secondary'>
          <p className='mb10 batch-card-expanded-header-secondary-details'>
            {batch.quantity} {batch.units}
            <div className='batch-card-header-secondary-icon'>
              <FontAwesomeIcon className='spacer-left' icon={faArrowUpShortWide} />
            </div>
          </p>
          <p className='mt10 mb10 batch-card-expanded-header-secondary-details'>
            {batch.usage_cycles}
            <div className='batch-card-header-secondary-icon'>
              <FontAwesomeIcon className='spacer-left' icon={faArrowsSpin} />
            </div>
          </p>
          <p className='mb0 batch-card-expanded-header-secondary-details'>
            <TruncatedTitle
              inline
              title={batch.materials[0].name}
              titleClassName='mb0'
              maxTextLength={25}
              maxTextLengthMobile={20}
            />
            <div className='batch-card-header-secondary-icon'>
              <FontAwesomeIcon className='spacer-left' icon={faObjectGroup} />
            </div>
          </p>
        </div>
      </div>
      <div className='batch-card-expanded-body'>
        <div className='batch-card-expanded-body-tabs'>
          <button
            type='button'
            className={`btn batch-card-expanded-body-tab 
            ${activeTab === BATCH_CARD_TABS.CONTAINERS ? 'batch-card-expanded-body-tab-active' : ''}`}
            onClick={() => handleSwitchTab(BATCH_CARD_TABS.CONTAINERS)}
          >
            <FontAwesomeIcon icon={faCoins} className='spacer-right' />
            Containers
          </button>
          <button
            type='button'
            className={`btn batch-card-expanded-body-tab 
            ${activeTab === BATCH_CARD_TABS.LOTS ? 'batch-card-expanded-body-tab-active' : ''}`}
            onClick={() => handleSwitchTab(BATCH_CARD_TABS.LOTS)}
          >
            <FontAwesomeIcon icon={faBoxOpen} className='spacer-right' />
            Lots
          </button>
        </div>

        <Slider
          ref={slickRef}
          swipe
          dots
          initialSlide={activeIndex}
          infinite={false}
          speed={200}
          className='batch-content-slick-container'
        >
          {activeTab === BATCH_CARD_TABS.CONTAINERS && renderContainersTab()}
          {activeTab === BATCH_CARD_TABS.LOTS && renderLotsTab()}
        </Slider>
        <button
          type='button'
          className='btn btn-link btn-sm text-white batch-card-expanded-toggle'
          onClick={togglePreview}
        >
          <FontAwesomeIcon icon={faDownLeftAndUpRightToCenter} />
        </button>
      </div>
    </div>
  );
};

BatchCardExpanded.defaultProps = {
  shouldShowBatchLink: true,
};

BatchCardExpanded.propTypes = {
  /* eslint-disable camelcase */
  batch: PropTypes.shape({
    uuid: PropTypes.string,
    units: PropTypes.string,
    status: PropTypes.string,
    quantity: PropTypes.number,
    powder_quality: PropTypes.string,
    usage_cycles: PropTypes.number,
    location_name: PropTypes.string,
    materials: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
      })
    ),
  }).isRequired,
  subLocation: PropTypes.string.isRequired,
  togglePreview: PropTypes.func.isRequired,
  cardState: PropTypes.shape({
    [BATCH_CARD_TABS.LOTS]: PropTypes.shape({
      fetching: PropTypes.bool,
      error: PropTypes.bool,
    }).isRequired,
    [BATCH_CARD_TABS.CONTAINERS]: PropTypes.shape({
      fetching: PropTypes.bool,
      error: PropTypes.bool,
    }).isRequired,
  }).isRequired,
  allContainers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  allLocationsByUri: PropTypes.shape({}).isRequired,
  allSubLocationsByUri: PropTypes.shape({}).isRequired,
  materialLots: PropTypes.arrayOf(
    PropTypes.shape({
      location: PropTypes.string,
      sub_location: PropTypes.string,
    })
  ).isRequired,
  shouldShowBatchLink: PropTypes.bool,
  isMobileDevice: PropTypes.bool.isRequired,
};
