import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import ActionContext from 'src/components/action-context';
import QrBarcodeToggle from 'src/components/qr-barcode-toggle';
import QrScan from 'src/components/qr-scan';
import FeaturesContext from 'src/context/FeaturesContext';
import UserContext from 'src/context/UserContext';
import { api } from 'src/utils/api';
import {
  API_RESOURCES,
  API_RESOURCES_MAP,
  BUREAU_BARCODE_FORMAT,
  LOCALSTORAGE_KEYS,
  MATERIAL_BATCH_ACTIONS,
  PERMANENT_CONTAINER_ACTIONS, RUN_ACTIONS,
} from 'src/utils/constants';
import { FEATURES, isFeatureEnabled } from 'src/utils/features';
import { getResourceName, getRoute, getRouteURI, getUuid } from 'src/utils/url';
import userPropType from 'src/utils/user-prop-type';

import BarcodeScan from '../components/barcode-scan';
import routes from '../utils/routes';
import useLocalstorage from '../utils/useLocalStorage';

const ScanPage = ({
                    user,
                  }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const {
    resource,
    action: initiatorAction,
    entity: requestedEntity,
    printer: printerUri,
    permanentContainer: permanentContainerUri,
    'material-batch': batchUri,
    isSameScan,
    skipSieving,
    sieveQuantity,
    actionFromBatch,
    initialSieveAction,
    runUri,
  } = Object.fromEntries(searchParams.entries()) ?? {};
  const [showInstructions, setShowInstructions] = useState(true);
  const [urlError, setUrlError] = useState(false);
  const [resourceError, setResourceError] = useState(false);
  const [successfulScan, setSuccessfulScan] = useState(Boolean(resource));
  const [customInstructionText, setCustomInstructionText] = useState('');

  const { features } = useContext(FeaturesContext);
  const isMaterialManagementEnabled = isFeatureEnabled(features, FEATURES.MATERIAL_MANAGEMENT);
  const isPermanentContainerUnloadAction = initiatorAction === PERMANENT_CONTAINER_ACTIONS.UNLOAD_UNUSED_MATERIAL
    || initiatorAction === PERMANENT_CONTAINER_ACTIONS.UNLOAD_RECLAIMED_MATERIAL;
  const isLoadPrinterAction = printerUri && initiatorAction === MATERIAL_BATCH_ACTIONS.MACHINE_LOAD;
  const isSieveBatchToPermanentContainerAction = initiatorAction === PERMANENT_CONTAINER_ACTIONS.SIEVE_BATCH;
  const isActionMachineLoad = initiatorAction === MATERIAL_BATCH_ACTIONS.MACHINE_LOAD;
  const isShipmentForOrderEnabled = isFeatureEnabled(features, FEATURES.SHIPMENT_FOR_ORDER);
  const [scanMode, handleScanMode] = useLocalstorage(LOCALSTORAGE_KEYS.SCAN_MODE, BUREAU_BARCODE_FORMAT.QR);
  const isBarcode = scanMode === BUREAU_BARCODE_FORMAT.BARCODE;

  const { setUser } = useContext(UserContext);

  const {
    containerActionData: { permanentContainersActionState },
  } = useContext(ActionContext);

  const handleResourceError = (error) => {
    if (error.message === 'NOT FOUND') {
      setUrlError(new Error('Resource not found. Please scan a valid resouce.'));
      setResourceError(true);
      return setSuccessfulScan(false);
    }

    setResourceError(true);
    return setUrlError(new Error('Something went wrong while scanning the resource. Please try again.'));
  };

  useEffect(() => {
    async function handleResourceRedirect() {
      try {
        // In case user is scanning new QR code - redirect him to appropriate page
        const resourceName = getResourceName(resource);
        const resourceUUID = getUuid(resource);
        switch (resourceName) {
          case API_RESOURCES.TOOLING_STOCK:
            navigate(`/tool/${resourceUUID}`);
            break;
          case API_RESOURCES.PRINTER:
            navigate(`/printer/${resourceUUID}/material`);
            break;
          case API_RESOURCES.SHIPMENT:
            navigate(`/shipment/${resourceUUID}`);
            break;
          case API_RESOURCES.MATERIAL_CONTAINER: {
            const container = await api.get(`material-container/${resourceUUID}/`).json();

            if (!container.disposable && !actionFromBatch) {
              return navigate(`/permanent-container/${resourceUUID}`);
            }

            if (container.current_batch) {
              // If a container is assigned to a batch - redirect to that batch actions page
              navigate(getRouteURI(routes.materialContainer, {}, { batch: encodeURIComponent(getUuid(container.current_batch)) }));
            } else {
              // Redirect user to initial batch creation page otherwise
              navigate(getRouteURI(routes.materialContainerDetails, { uuid: resourceUUID }));
            }
            break;
          }
          default:
            setSuccessfulScan(false);
            break;
        }
      } catch (error) {
        handleResourceError(error);
      }
    }

    if (resource) {
      handleResourceRedirect();
    }
  }, [resource]);

  useEffect(() => {
    if (user?.initialLogin) {
      // Reset the initial redirect to /scan page when log in
      setUser({ ...user, initialLogin: false });
    }
  }, [user]);

  useEffect(() => {
    if (requestedEntity === API_RESOURCES.RUN) {
      setCustomInstructionText(`Please scan 
          ${isBarcode ? 'the Barcode' : 'the QR Code'} of a Tool 
          ${isBarcode ? 'using your Barcode Scanner' : ''} to assign it to this Run.
          ${isBarcode ? 'Then you will be automatically taken to the relevant page.' : ''}`);
    }
  }, [requestedEntity, isBarcode]);

  const triggerScanError = (errorText) => {
    setUrlError(new Error(errorText));
    setSuccessfulScan(false);
  };

  async function handleScan(url, resourceName, resourceUri) {
    // url field is deprecated and just for legacy field support,
    // you might not want to use it.

    setShowInstructions(false);
    setUrlError(false);
    setSuccessfulScan(true);

    // In case user was redirected to this page in order to scan additional QR Code
    if (initiatorAction) {
      // Set initial URIs, if any provided in URL
      let scannedContainerBatchUri;
      let scannedPermanentContainerUri;
      if (resourceName === API_RESOURCES.MATERIAL_BATCH) {
        // It's possible to enter it manually
        scannedContainerBatchUri = resourceUri;
      }

      if (resourceName === API_RESOURCES.MATERIAL_CONTAINER) {
        if (
          (isPermanentContainerUnloadAction || isSieveBatchToPermanentContainerAction) &&
          isSameScan &&
          resourceUri &&
          permanentContainersActionState.containersScanned[getUuid(resourceUri)]
        ) {
          const errorMessage =
            `The container scanned has already been processed and is listed for 
            ${isPermanentContainerUnloadAction ? 'material unloading' : 'sieving batch'}.\nPlease scan a different container.`;

          return triggerScanError(errorMessage);
        }

        // Fill missing data based on scanned resource
        const materialContainer = await api.get(resourceUri, { prefixUrl: false }).json();
        const isLoadAction = initiatorAction === PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL ||
          initiatorAction === PERMANENT_CONTAINER_ACTIONS.TOP_OFF ||
          initiatorAction === MATERIAL_BATCH_ACTIONS.MACHINE_LOAD;
        const isUnloadAction = initiatorAction === PERMANENT_CONTAINER_ACTIONS.UNLOAD_UNUSED_MATERIAL
          || initiatorAction === PERMANENT_CONTAINER_ACTIONS.UNLOAD_RECLAIMED_MATERIAL;

        if ((isLoadAction && materialContainer) && materialContainer.disposable &&
          requestedEntity !== API_RESOURCES.MATERIAL_BATCH) {
          return triggerScanError('Materials may only be loaded into the Permanent Container');
        }

        if ((isSieveBatchToPermanentContainerAction && materialContainer) && materialContainer.disposable) {
          return triggerScanError('Materials may only be sieved into the Permanent Container');
        }

        /* Sieve Batch into the Permanent Container is possible only if the Permanent Container
           does not have the batch assigned (is Empty) or if it has the same Batch
           (which means we will sieve the amount into the same container) */

        if ((isSieveBatchToPermanentContainerAction && materialContainer)
          && (!!materialContainer.current_batch && materialContainer.current_batch !== batchUri)) {
          return triggerScanError('The permanent container you have scanned is already loaded with a different batch');
        }

        if ((isLoadAction && materialContainer) && materialContainer.current_batch === batchUri) {
          return triggerScanError('The permanent container you have scanned is already loaded with the same batch');
        }

        if (isUnloadAction && materialContainer.disposable) {
          return triggerScanError('Materials may only be unloaded into the Permanent Container');
        }

        // We can scan the Permanent Container which Has No Batch in it only to Unload Unused Material (Hopper)
        if (isUnloadAction && !materialContainer.disposable && materialContainer.current_batch) {
          return triggerScanError('Materials may only be unloaded into an Empty Permanent Container');
        }

        if (!materialContainer.current_batch && materialContainer.disposable) {
          // Containers not added to any batch are not allowed to be used for any action
          // except Initial Batch creation
          return triggerScanError('The container that you have scanned is not part of a batch. Please scan the container and create a batch before attempting to load the container into the machine.');
        }
        scannedPermanentContainerUri = resourceUri;
        scannedContainerBatchUri = materialContainer.current_batch;
      }

      // Redirect user to appropriate page adding all required params
      switch (initiatorAction) {
        case MATERIAL_BATCH_ACTIONS.MACHINE_LOAD: {
          // Set initial URIs, if any provided in URL
          let materialBatchUriForRedirect = scannedContainerBatchUri || batchUri;
          let resourceUriForRedirect = printerUri;

          if (resourceName === API_RESOURCES.PRINTER ||
            (resourceName === API_RESOURCES.MATERIAL_CONTAINER
              && requestedEntity !== API_RESOURCES.MATERIAL_BATCH)) {
            resourceUriForRedirect = resourceUri;
          }

          if (resourceName === API_RESOURCES.MATERIAL_CONTAINER
            && requestedEntity !== API_RESOURCES.MATERIAL_BATCH) {
            const permanentContainerQueryParams = {
              type: PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL,
              batch: batchUri,
            };

            navigate(getRouteURI(routes.permanentContainerAction,
              { uuid: getUuid(resourceUriForRedirect) },
              permanentContainerQueryParams));
            return;
          }

          const batchQueryParams = {
            type: initiatorAction,
            printer: resourceUriForRedirect,
            actionFromBatch: actionFromBatch,
          };

          navigate(getRouteURI(routes.materialBatchAction,
            { uuid: getUuid(materialBatchUriForRedirect) },
            batchQueryParams));

          return;
        }
        case PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL: {
          // Set initial URIs, if any provided in URL
          const materialBatchUri = scannedContainerBatchUri || batchUri;

          if (!materialBatchUri) {
            return triggerScanError('The permanent container you scanned is empty. Please scan a permanent container with material.');
          }

          const permanentContainerQueryParams = {
            type: initiatorAction,
            batch: materialBatchUri,
          };

          navigate(getRouteURI(routes.permanentContainerAction,
            { uuid: getUuid(permanentContainerUri) },
            permanentContainerQueryParams));
          return;
        }
        case PERMANENT_CONTAINER_ACTIONS.TOP_OFF: {
          // Set initial URIs, if any provided in URL
          const materialBatchUri = scannedContainerBatchUri || batchUri;

          if (!materialBatchUri) {
            return triggerScanError('The permanent container you scanned is empty. Please scan a permanent container with material.');
          }

          const permanentContainerQueryParams = {
            batch: materialBatchUri,
          };

          navigate(getRouteURI(routes.permanentContainerAlreadyLoaded,
            { uuid: getUuid(permanentContainerUri) },
            permanentContainerQueryParams));
          return;
        }
        case PERMANENT_CONTAINER_ACTIONS.TRANSFER_MATERIAL: {
          // Set initial URIs, if any provided in URL
          const materialBatchUri = scannedContainerBatchUri || batchUri;
          const permanentContainerUriToRedirect = scannedPermanentContainerUri || permanentContainerUri;

          const permanentContainerQueryParams = {
            type: initiatorAction,
            batch: materialBatchUri,
          };

          navigate(getRouteURI(routes.permanentContainerAction,
            { uuid: getUuid(permanentContainerUriToRedirect) },
            permanentContainerQueryParams));
          return;
        }
        case PERMANENT_CONTAINER_ACTIONS.UNLOAD_UNUSED_MATERIAL:
        case PERMANENT_CONTAINER_ACTIONS.UNLOAD_RECLAIMED_MATERIAL: {
          // Set initial URIs, if any provided in URL
          const permanentContainerUriToRedirect = scannedPermanentContainerUri || permanentContainerUri;

          const permanentContainerQueryParams = {
            type: initiatorAction,
            batch: batchUri,
            printer: printerUri,
          };

          navigate(getRouteURI(routes.permanentContainerAction,
            { uuid: getUuid(permanentContainerUriToRedirect) },
            permanentContainerQueryParams));
          return;
        }
        case PERMANENT_CONTAINER_ACTIONS.SIEVE_BATCH: {
          // Set initial URIs, if any provided in URL
          const permanentContainerUriToRedirect = scannedPermanentContainerUri || permanentContainerUri;

          const permanentContainerQueryParams = {
            type: initiatorAction,
            batch: batchUri,
            sieveQuantity,
          };

          if (initialSieveAction) {
            permanentContainerQueryParams.initialSieveAction = initialSieveAction;
          }

          navigate(getRouteURI(routes.permanentContainerAction,
            { uuid: getUuid(permanentContainerUriToRedirect) },
            permanentContainerQueryParams));
          return;
        }
        case MATERIAL_BATCH_ACTIONS.MACHINE_TOP_OFF: {
          // Set initial URIs, if any provided in URL
          let materialBatchUriForRedirect = scannedContainerBatchUri || batchUri;
          let printerUriForRedirect = printerUri;

          if (resourceName === API_RESOURCES.MATERIAL_BATCH) {
            materialBatchUriForRedirect = resourceUri;
          }

          const printerQueryParams = {
            batch: materialBatchUriForRedirect,
            actionFromBatch,
          };

          navigate(getRouteURI(routes.printerAlreadyLoaded,
            { uuid: getUuid(printerUriForRedirect) },
            printerQueryParams));
          return;
        }

        case MATERIAL_BATCH_ACTIONS.BLEND_BATCHES: {

          const batchQueryParams = {
            type: initiatorAction,
            blendedBatch: scannedContainerBatchUri,
            skipSieving,
            actionFromBatch,
          };

          navigate(getRouteURI(routes.materialBatchAction,
            { uuid: getUuid(batchUri) },
            batchQueryParams));
          return;
        }

        case RUN_ACTIONS.SCAN_TOOLING_STOCK: {
          const runQueryParams = {
            type: initiatorAction,
            runUri,
            toolingStockUUID: getUuid(resourceUri),
          };

          navigate(getRouteURI(routes.runAction,
            { uuid: getUuid(runUri) },
            runQueryParams));
          return;
        }

        default:
          break;
      }
    }

    if (!url && resourceName && resourceUri) {
      // Looks like it can be scanned code from initial view
      // (nothing is requested to scan yet)

      if (resourceName === API_RESOURCES.MATERIAL_BATCH) {
        // We don't have batch detail page, so we need to
        // redirect to material container route with only batch uuid in query params
        // (suppose that all containers of batch are scanned)

        navigate(`/material-container?batch=${getUuid(resourceUri)}`);

        // Router.push(
        //   {
        //     pathname: '/material-container',
        //     query: { batch: getUuid(resourceUri) },
        //   }
        // );
      } else {
        setUrlError(new Error('This resource is not supported yet'));
        setSuccessfulScan(false);
      }
    }

    if (url) {
      navigate(getRoute(url));
    }
  }

  function handleError(error) {
    setUrlError(error);
  }

  const getAllowedEntityType = () => {
    if (requestedEntity === API_RESOURCES.MATERIAL_BATCH) {
      return API_RESOURCES.MATERIAL_CONTAINER;
    }

    return requestedEntity;
  };
  // When no entity requested - allowed entity type is not set (which means - Any is allowed)
  const allowedEntityType = getAllowedEntityType();

  const getAllowedEntityName = () => {
    if (requestedEntity) {
      if (!API_RESOURCES_MAP[allowedEntityType]) {
        // Adding exception as a fall-back.
        // Anyway, should not be visible in real life
        // unless someone changes the URL manually
        throw new Error(`Requested QR code type "${requestedEntity}" is not supported.`);
      }
      return API_RESOURCES_MAP[allowedEntityType];
    }

    // By default we have QR codes only for line-item+copy (called traveler initially)
    const allowedEntities = ['traveler'];
    if (isShipmentForOrderEnabled) {
      allowedEntities.push(API_RESOURCES_MAP[API_RESOURCES.SHIPMENT]);
    }
    if (isMaterialManagementEnabled) {
      allowedEntities.push(
        API_RESOURCES_MAP[API_RESOURCES.MATERIAL_CONTAINER],
        API_RESOURCES_MAP[API_RESOURCES.PRINTER],
      );
    }
    if (allowedEntities.length === 1) {
      // Only 1 entity is allowed.
      return allowedEntities[0];
    }

    // Separating the last item to make it `xxx, yyy, zzz OR mmm`
    // Where `xxx, yyy, zzz` is all except last and `mmm` is the last item
    const allowedEntitiesExceptLast = allowedEntities.slice(0, -1);
    return `${allowedEntitiesExceptLast.join(', ')} or ${allowedEntities[allowedEntities.length - 1]}`;
  };

  const instructionText = `Use your camera to scan the QR code on the ${getAllowedEntityName()}.`;
  const barcodeInstructionText = `Scan the barcode on a ${getAllowedEntityName()} 
  using your Barcode Scanner, and you will be automatically taken to the relevant page.`;

  const toggleScanMode = () => handleScanMode(isBarcode ? BUREAU_BARCODE_FORMAT.QR : BUREAU_BARCODE_FORMAT.BARCODE);

  const renderUseWithoutPermanentContainer = useCallback(() => {
    if (
      (initiatorAction === PERMANENT_CONTAINER_ACTIONS.UNLOAD_UNUSED_MATERIAL ||
        initiatorAction === PERMANENT_CONTAINER_ACTIONS.UNLOAD_RECLAIMED_MATERIAL) &&
      !isSameScan) {

      const action = initiatorAction === PERMANENT_CONTAINER_ACTIONS.UNLOAD_UNUSED_MATERIAL ?
        MATERIAL_BATCH_ACTIONS.MACHINE_UNLOAD_HOPPER :
        MATERIAL_BATCH_ACTIONS.UNLOAD_RECLAIMED_MATERIAL;

      return (
        <Link
          to={`/material-batch/${getUuid(batchUri)}/action?type=${action}`}
          className="link-btn"
        >
          <button
            type="button"
            className="btn btn-lg btn-primary btn-block"
            disabled={!batchUri}
          >
            Unload without permanent container
          </button>
        </Link>
      );
    }

    return null;

  }, [batchUri, initiatorAction]);

  const content = isBarcode ? (
    <BarcodeScan
      user={user}
      allowedEntityType={allowedEntityType}
      successfulScan={successfulScan}
      handleScan={handleScan}
      handleError={handleError}
      barcodeError={urlError}
      showInstructions={showInstructions}
      instructionText={customInstructionText || barcodeInstructionText}
      requestedEntity={requestedEntity}
      setBarcodeError={setUrlError}
      resourceError={resourceError}
      renderUseWithoutPermanentContainer={renderUseWithoutPermanentContainer}
    />
  ) : (
    <QrScan
      user={user}
      allowedEntityType={allowedEntityType}
      successfulScan={successfulScan}
      handleScan={handleScan}
      handleError={handleError}
      urlError={urlError}
      showInstructions={showInstructions}
      instructionText={customInstructionText || instructionText}
      requestedEntity={requestedEntity}
      renderUseWithoutPermanentContainer={renderUseWithoutPermanentContainer}
      isUnloadAction={isPermanentContainerUnloadAction || isSieveBatchToPermanentContainerAction}
      isActionMachineLoad={isActionMachineLoad}
      isLoadPrinterAction={isLoadPrinterAction}
    />
  );


  return (
    <>
      {content}
      <QrBarcodeToggle scanMode={scanMode} toggleScanMode={toggleScanMode} action={initiatorAction} />
    </>
  );
};

ScanPage.propTypes = {
  user: userPropType.isRequired,
};

export default ScanPage;
