import React, { useContext } from 'react';
import { Route, Routes } from 'react-router-dom';
import FeaturesContext from 'src/context/FeaturesContext';
import Tool from 'src/pages/tool/[uuid]';
import ToolAction from 'src/pages/tool/[uuid]/action';
import PrivateRoute from 'src/routes/PrivateRoute';
import { FEATURES, isFeatureEnabled } from 'src/utils/features';

import NotFoundPage from '../pages/not-found';

const ToolRoutes = () => {
  const { features } = useContext(FeaturesContext);
  const isToolingEnabled = isFeatureEnabled(features, FEATURES.TOOLING_STOCK);

  return (
    <Routes>
      {isToolingEnabled && (
        <Route index path='/:uuid' element={<PrivateRoute Component={Tool} />} />
      )}
      {isToolingEnabled && (
        <Route index path='/:uuid/action' element={<PrivateRoute Component={ToolAction} />} />
      )}
      <Route path='*' element={<NotFoundPage />} />
    </Routes>
  );
};

export default ToolRoutes;
