import config from './config';

const RESOURCES_TO_DECODE = {
  SHIPMENT: 'shipment',
  CONTAINER: 'material-container',
  PRINTER: 'printer',

  PIECE: 'piece',
  RUN: 'run',
  SUMMARY: 'summary',
};

// Defining all the resources and the related keys for decoding/encoding
const ENCODED_RESOURCES_CONSTANTS = {
  SHIPMENT: 'E',
  CONTAINER: 'A',
  PRINTER: 'D',

  RUN: 'B',
  PIECE: 'C',
  SUMMARY: 'Z',
};

export function encodedBarcodeSummaryUrl(url) {
  // Assuming that the Summary Page URL for the Travelers will look this way (e.g.):
  // https://qr.stage-auth.com/summary?line_item=af12e609-9953-40c5-90b8-3f13453637dc&copy=5
  // We can parse the URL and extract parameters:

  try {
    const urlParts = new URL(url);
    const uuid = urlParts.searchParams.get('line_item')?.replace(/-/g, '');
    const copy = urlParts.searchParams.get('copy');

    // Validate the UUID and copy number formats
    if (!uuid || !/^[\da-f]{32}$/i.test(uuid) || !copy || !/^\d{1,3}$/.test(copy)) {
      console.error('Barcode URL Encoding Error: UUID or copy format is incorrect');
      return null;
    }
    // Encode and return the formatted string
    return `${ENCODED_RESOURCES_CONSTANTS.SUMMARY}${uuid}${copy}`;
  } catch (error) {
    console.error('Barcode URL Encoding Error: Invalid URL');
    return null;
  }
}

export function encodedBarcodeUrl(url) {
  // Assuming the resource URL is in the format (e.g.):
  // https://data.stage-auth.com/printer/d1a49b26-d61a-4c0c-819a-d9c0084d08a5/
  // That is the only format we should support for encoding.
  const parts = url.split('/').filter(part => part.length > 0);
  // Parts for the URL above should be:
  // ['https:', 'data.stage-auth.com', 'printer', 'd1a49b26-d61a-4c0c-819a-d9c0084d08a5']
  // If the structure of the URI is incorrect, return null;
  if (parts.length < 2) {
    console.error('Barcode URL Encoding Error: URL structure is incorrect');
    return null;
  }

  // Resource type for the above URL will be 'printer'
  const resourceType = parts[parts.length - 2];
  // Removing hyphens from the UUID: 'd1a49b26d61a4c0c819ad9c0084d08a5'
  const uuid = parts[parts.length - 1].replace(/-/g, '');

  // If the UUID format is incorrect for encoding -> return null
  if (!/^[\da-f]{32}$/i.test(uuid)) {
    console.error('Barcode URL Encoding Error: UUID format is incorrect');
    return null;
  }

  // For the above URL, resourceKey will be 'PRINTER'
  const resourceKey = Object.keys(RESOURCES_TO_DECODE).find(
    key => RESOURCES_TO_DECODE[key] === resourceType
  );

  // Return null if the resource key is not found
  if (!resourceKey) {
    console.error('Barcode URL Encoding Error: Resource key not found');
    return null;
  }

  // Resource letter for the above URL will be 'D' due to ENCODED_RESOURCES_CONSTANTS
  const resourceLetter = ENCODED_RESOURCES_CONSTANTS[resourceKey];
  // Return result: 'Dd1a49b26d61a4c0c819ad9c0084d08a5'
  // WHERE: 'D' is the resource letter and 'd1a49b26d61a4c0c819ad9c0084d08a5' is the UUID
  return `${resourceLetter}${uuid}`;
}

export function checkEncodedValue(encodedValue) {
  // Get the resource letter from the encoded value
  const resourceLetter = encodedValue.charAt(0);

  // Check for the summary URL case (starts with 'Z')
  if (resourceLetter === ENCODED_RESOURCES_CONSTANTS.SUMMARY) {
    // Regular expression to match Z, exactly 32 hex characters for UUID, and 1-3 digits for copy number
    const summaryRegex = /^Z([\da-f]{32})(\d{1,3})$/i;
    // Returns true if the encoded value matches the summary URL format
    return summaryRegex.test(encodedValue);
  }
  // Check for the standard URL case
  else {
    if (encodedValue.length !== 33) {
      return false; // Returns false if the encoded value length is not 33 characters for standard URLs
    }

    const uuid = encodedValue.slice(1);
    // Check if the resource letter is valid
    if (!Object.values(ENCODED_RESOURCES_CONSTANTS).includes(resourceLetter)) {
      return false; // Returns false if the resource letter is invalid
    }
    // Returns false if the UUID format is invalid or true if all checks pass
    return /^[\da-f]{32}$/i.test(uuid);
  }
}

// Helper function to format UUID (add hyphens) for Decoding
export const formatUUIDWithHyphens = uuid =>
  uuid.replace(/^([\da-f]{8})([\da-f]{4})([\da-f]{4})([\da-f]{4})([\da-f]{12})$/, '$1-$2-$3-$4-$5');

export function decodeBarcodeUrl(encodedValue, environmentUrl = `${window.location.origin}`) {
  // Decoding for summary page URL
  if (encodedValue.startsWith(ENCODED_RESOURCES_CONSTANTS.SUMMARY)) {
    // Regular expression to match Z, 32 hex characters for UUID, and 1-3 digits for copy number
    const summaryRegex = /^Z([\da-f]{32})(\d{1,3})$/i;
    const match = encodedValue.match(summaryRegex);
    if (match) {
      // Return the decoded URL
      return `${environmentUrl}/summary?line_item=${formatUUIDWithHyphens(match[1])}&copy=${match[2]}`;
    }
  } else if (
    // Decoding for regular resource link URL
    encodedValue.length === 33 &&
    Object.values(ENCODED_RESOURCES_CONSTANTS).includes(encodedValue.charAt(0))
  ) {
    // Get the resource letter from the encoded value
    const resourceLetter = encodedValue.charAt(0);
    // Get the UUID from the encoded value
    const uuid = formatUUIDWithHyphens(encodedValue.slice(1));
    // Get the resource key from the resource letter (e.g. 'D' -> 'PRINTER')
    const resourceKey = Object.keys(ENCODED_RESOURCES_CONSTANTS).find(
      key => ENCODED_RESOURCES_CONSTANTS[key] === resourceLetter
    );

    // Return the Resource Path (e.g. 'PRINTER' -> 'printer')
    const resourcePath = RESOURCES_TO_DECODE[resourceKey];
    return `${environmentUrl}/scan?resource=${config.apiHost}/${resourcePath}/${uuid}/`;
  }

  console.error('Barcode URL Decoding Error: Unrecognized or invalid encoded value format');
  return null;
}
