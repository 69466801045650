import React, {  useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Header from 'src/components/header';
import NotFound from 'src/components/not-found';
import { ToolingStockCardPreview } from 'src/components/ToolingStockCard';
import { apiWithContext } from 'src/utils/api';
import {
  API_RESOURCES,
} from 'src/utils/constants';
import { getUuid } from 'src/utils/url';
import userPropType from 'src/utils/user-prop-type';


const Tool = ({ user }) => {
  const { uuid } = useParams();

  const [tool, setTool] = useState();
  const [location, setLocation] = useState();
  const [subLocation, setSubLocation] = useState();
  const [type, setType] = useState();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const fetchData = async () => {
    if (!uuid) return ;
    setLoading(true);

    const api = apiWithContext();
    try {
      // Fetch tooling stock data
      const tool = await api.get(`${API_RESOURCES.TOOLING_STOCK}/${uuid}`).json();

      if (!tool) {
        return null;
      }
      setTool(tool);

      const promises = [
      api.get(`${API_RESOURCES.TOOLING_TYPE}/${getUuid(tool.type)}/`).json(),
      ];
      if (tool.location) {
      promises.push(api.get(`${API_RESOURCES.LOCATION}/${getUuid(tool.location)}/`).json())
      }
      if (tool.sub_location) {
      promises.push(
        api.get(`${API_RESOURCES.SUB_LOCATION}/${getUuid(tool.sub_location)}/`).json()
      )
      }
      // Fetch tooling stock location, sub-location, and tooling type in parallel
      const [
        type,
        location,
        subLocation,
      ] = await Promise.all(promises);

      setLocation(location);
      setSubLocation(subLocation);
      setType(type);
    } catch (error) {
      console.error(error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [uuid]);

  if (error) {
    return (
      <>
        <Header title="Not Found" back="/scan" user={user} />
        <main role="main" className="text-center">
          <NotFound id={uuid} />
        </main>
      </>
    );
  }

  if (loading || !tool) {
    return (
      <>
        <Header title="Loading Tool..." user={user} />
        <main role="main" className="text-center">
          <div className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true" />
          Loading...
        </main>
      </>
    );
  }

  return (
    <>
      <Header title={tool?.name} user={user} />
      <main role="main" className="text-center">
        <ToolingStockCardPreview expanded toolingStock={tool} toolingType={type} location={location?.name} subLocation={subLocation?.name} />
        <Link to={`/tool/${uuid}/action`}>
          <button
            type="button"
            className="btn btn-lg btn-primary btn-block"
          >
            Perform Action
          </button>
        </Link>
      </main>
    </>
  );
};

Tool.propTypes = {
  user: userPropType.isRequired,
};

export default Tool;
