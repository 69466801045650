import PropTypes from 'prop-types';
import React from 'react';


const Thumbnail = ({ thumbnail }) => {
  if (!thumbnail) {
    return null;
  }

  return (
    <div className="thumbnail-box rounded-sm">
      <div className="thumbnail-box-inside">
        <img className="thumbnail" src={thumbnail} alt="thumbnail" />
      </div>
    </div>
  );
};

Thumbnail.propTypes = {
  thumbnail: PropTypes.string,
};

export default Thumbnail;
