import {
  faArrowAltCircleRight,
  faInfoCircle,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Header from 'src/components/header';
import Loader from 'src/components/loader';
import NotFound from 'src/components/not-found';
import ScanButton from 'src/components/scan-button';
import { api } from 'src/utils/api';
import { API_RESOURCES } from 'src/utils/constants';
import { getRouteURI, getUuid } from 'src/utils/url';
import userPropType from 'src/utils/user-prop-type';

import routes from '../../../utils/routes';
import LineItem from './sections/_line-item';
import Order from './sections/_order';
import PieceHeader from './sections/_piece-header';
import Thumbnail from './sections/_thumbnail';
import Workflow from './sections/_workflow';

const PieceOverview = ({ user }) => {
  const { uuid: pieceUUID } = useParams();
  const [piece, setPiece] = useState(undefined);
  const [runUUID, setRunUUID] = useState(undefined);
  const [error, setError] = useState(undefined);
  const [thumbnail, setThumbnail] = useState(null);

  const fetchInitialData = async () => {
    try {
      const piece = await api.get(`${API_RESOURCES.PIECE}/${pieceUUID}/`).json();
      setPiece(piece);
      if (!piece.current_print) {
        setError('Failed to retrieve piece');
        return;
      }
      const currentPrint = await api.get(piece.current_print, { prefixUrl: false }).json();
      if (!currentPrint) {
        setError('Failed to retrieve print');
      }
      setRunUUID(getUuid(currentPrint.run));
    } catch (error) {
      if (error.name === 'HTTPError' && error.response.status === 404) {
        setError('Failed to find piece');
      } else {
        setError(error);
      }
    }
  };

  useEffect(() => {
    void fetchInitialData();
  }, [pieceUUID]);


  if (error) {
    const errorText = error?.text;
    return (
      <>
        <Header title="Not Found" back="/scan" user={user} />
        <main role="main" className="text-center">
          <NotFound id={pieceUUID} text={errorText} />
        </main>
      </>
    );
  }

  if (!runUUID) {
    return (
      <>
        <Header title={piece?.name} user={user} />
        <main role="main" className="text-center">
          <FontAwesomeIcon icon={faInfoCircle} className="mr-1" />
          <Loader inline text="piece" className="text-muted" />
        </main>
      </>
    );
  }

  return (
    <>
      <Header title={`Piece Overview: ${piece?.name}`} user={user} />
      <main role="main" className="text-center">
        <PieceHeader piece={piece} runUUID={runUUID} />
        <Thumbnail thumbnail={thumbnail} />
        <Order orderID={getUuid(piece.order)} />
        <LineItem lineItemID={getUuid(piece.line_item)} setThumbnail={setThumbnail} />
        <Workflow pieceURI={piece.uri} workflowID={getUuid(piece.workflow)} />

        <div className="d-flex justify-content-center">
          <ScanButton />
          <Link to={getRouteURI(routes.run, { uuid: runUUID })}>
            <div className="btn btn-outline-success btn-action ml-4 mr-4 mt-4">
              <FontAwesomeIcon icon={faArrowAltCircleRight} size="3x" className="d-block m-auto" />
              View Run
            </div>
          </Link>
        </div>
      </main>
    </>
  );
};

PieceOverview.propTypes = {
  user: userPropType.isRequired,
};
export default PieceOverview;
