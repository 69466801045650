import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { truncate } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import useMediaQuery from 'src/hooks/useMediaQuery';

const TruncatedTitle = ({
  title,
  titleAdditionalTextNoTruncate,
  titleClassName,
  icon,
  iconClassName,
  inline,
  maxTextLength,
  maxTextLengthMobile,
  tooltipPlacement = 'top',
}) => {
  const isMobileDevice = useMediaQuery('(min-width: 300px) and (max-width: 600px)');
  const maxTitleLength = isMobileDevice ? maxTextLengthMobile : maxTextLength;

  if (!title) return null;

  return (
    <p className={`mb0 ${inline ? 'd-inline-block' : ''}`}>
      {icon && <FontAwesomeIcon className={`spacer-right ${iconClassName}`} icon={icon} />}
      {title.length >= maxTitleLength ? (
        <OverlayTrigger placement={tooltipPlacement} overlay={<Tooltip>{title}</Tooltip>}>
          <span className={titleClassName}>
            {truncate(title, { length: maxTitleLength })}
            {titleAdditionalTextNoTruncate && (
              <span className='spacer-left'>{titleAdditionalTextNoTruncate}</span>
            )}
          </span>
        </OverlayTrigger>
      ) : (
        <span className={titleClassName}>
          {title}
          {titleAdditionalTextNoTruncate && (
            <span className='spacer-left'>{titleAdditionalTextNoTruncate}</span>
          )}
        </span>
      )}
    </p>
  );
};

TruncatedTitle.propTypes = {
  title: PropTypes.string,
  titleAdditionalTextNoTruncate: PropTypes.string,
  titleClassName: PropTypes.string,
  icon: PropTypes.shape({}),
  iconClassName: PropTypes.string,
  maxTextLength: PropTypes.number.isRequired,
  maxTextLengthMobile: PropTypes.number,
  tooltipPlacement: PropTypes.string,
  inline: PropTypes.bool,
};

TruncatedTitle.defaultProps = {
  title: PropTypes.string,
  titleAdditionalTextNoTruncate: '',
  icon: null,
  iconClassName: '',
  maxTextLengthMobile: 15,
  tooltipPlacement: 'top',
  inline: false,
};

export default TruncatedTitle;
