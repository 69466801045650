import React, {  useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Header from 'src/components/header';
import NotFound from 'src/components/not-found';
import { apiWithContext } from 'src/utils/api';
import {
  API_RESOURCES, TOOLING_STOCK_ACTION_TYPES,
} from 'src/utils/constants';
import userPropType from 'src/utils/user-prop-type';

import ActionForm from './action-form';


const ToolAction = ({ user }) => {
  const { uuid } = useParams();

  const [tool, setTool] = useState();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [submitting, setSubmitting] = useState(false);

  const navigate = useNavigate();

  const fetchData = async () => {
    if (!uuid) return ;
    setLoading(true);

    const api = apiWithContext();
    try {
      const tool = await api.get(`${API_RESOURCES.TOOLING_STOCK}/${uuid}/`).json();

      if (!tool) {
        return null;
      }
      setTool(tool);
    } catch (error) {
      console.error(error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [uuid]);

  const getMetadata = (form) => {
    switch (form.action) {
      case TOOLING_STOCK_ACTION_TYPES.MAKE_READY_FOR_USE: // fallthrough
      case TOOLING_STOCK_ACTION_TYPES.RELOCATE: 
        return {
          // eslint-disable-next-line camelcase
          destination_location: form.location,
          // eslint-disable-next-line camelcase
          destination_sub_location: form.subLocation === '' ? null : form.subLocation,
        }
      
      case TOOLING_STOCK_ACTION_TYPES.RECORD_TESTS: 
      return {
        // eslint-disable-next-line camelcase
        test_results: form.notes,
      }

      case TOOLING_STOCK_ACTION_TYPES.COMPLETE_REFURBISH: 
      return {
        // eslint-disable-next-line camelcase
        test_data: form.notes,
      }
    }
    return {};
  }

  const handleSubmit = async (form) => {
    if(!form) return;
    setSubmitting(true);
    const api = apiWithContext();
    const payload = {
      metadata: getMetadata(form),
      // eslint-disable-next-line camelcase
      tooling_stock: tool.uri,
      // eslint-disable-next-line camelcase
      action_type: form.action,
    };

    if (form.action !== TOOLING_STOCK_ACTION_TYPES.COMPLETE_REFURBISH && 
      form.action !== TOOLING_STOCK_ACTION_TYPES.RECORD_TESTS) {
      payload.notes = form.notes;
    }

    await api.post(`${API_RESOURCES.TOOLING_STOCK_ACTION}/`, {
      json: payload,
    }).json();
    setSubmitting(false);
    navigate(`/tool/${uuid}`)
  };

  if (error) {
    return (
      <>
        <Header title="Not Found" back="/scan" user={user} />
        <main role="main" className="text-center">
          <NotFound id={uuid} />
        </main>
      </>
    );
  }

  if (loading || !tool) {
    return (
      <>
        <Header title="Loading Tool..." user={user} />
        <main role="main" className="text-center">
          <div className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true" />
          Loading...
        </main>
      </>
    );
  }

  return (
    <>
      <Header title={tool?.name} user={user} />
      <main role="main" className="text-center">
        <ActionForm tool={tool} handleSubmit={handleSubmit} setError={setError} isSubmitting={submitting} />
        <Link to={`/tool/${uuid}`}>
          <button type="button" className="btn btn-default btn-action">
            Cancel
          </button>
        </Link>
      </main>
    </>
  );
};

ToolAction.propTypes = {
  user: userPropType.isRequired,
};

export default ToolAction;
